import { TextField } from "@mui/material";
import { styled } from "@mui/styles";
import { ErrorMessage, useField } from "formik";

export const TextInput = styled(TextField)(({ focused }) => {
  return {
    "& .MuiInputBase-root": {
      backgroundColor: "#F5F5F5",
      border: "none",
    },
    "& .MuiInputBase-root.Mui-Focused": {
      backgroundColor: "#FFFFFF",
      border: "1px solid #0E4FFA",
    },
    "& fieldset": {
      border: "none",
    },
    "& input": {
      backgroundColor: "#F5F5F5",
      borderRadius: 2,
      border: "none",
    },
    "& input:focus": {
      backgroundColor: "#FFFFFF",
      border: "1px solid #0E4FFA",
    },
  };
});

export const ValidatedTextInput = ({ exception, children, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <TextInput
      value={field.value}
      {...props}
      {...field}
      error={Boolean(meta.touched && meta.error)}
      helperText={
        Boolean(exception) ? (
          exception
        ) : meta.error ? (
          <ErrorMessage name={field.name} />
        ) : (
          ""
        )
      }
      fullWidth>
      {children}
    </TextInput>
  );
};
