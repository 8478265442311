import React from "react";
import { Form, Formik } from "formik";
import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ValidatedTextInput } from "shared/TextInput";
import { makeStyles } from "@mui/styles";

export default function GetFormParser({
  fields,
  action,
  style,
  radioGroupValue = () => {},
  changeRadioGroup = () => {},
  disableEverithing = false,
  ...props
}) {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const getInput = (input, formik) => {
    switch (input.type) {
      case "text": {
        return (
          <ValidatedTextInput
            type="text"
            name={input.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values[input.name]}
            placeholder={input.placeholder}
            multiline={Boolean(input.multiline)}
          />
        );
      }
      case "select": {
        return (
          <ValidatedTextInput
            select
            name={input.name}
            onBlur={formik.handleBlur}
            onChange={(event) => {
              formik.handleChange(event);
              if (input.onChange) {
                input.onChange(event);
              }
            }}
            value={formik.values[input.name]}
            placeholder={input.placeholder}
            label={input.selectLabel}>
            {input.variants.map((item, index) => (
              <MenuItem key={item.value + index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </ValidatedTextInput>
        );
      }
      case "radio": {
        return (
          <RadioGroup
            name={input.name}
            value={formik.values[input.name]}
            onChange={(e) => {
              changeRadioGroup(e.target);
              formik.handleChange(e);
            }}
            disabled={disableEverithing}
            row={input.variants.length <= 2}>
            {input.variants.map((variantItem) => (
              <FormControlLabel
                key={variantItem.label}
                value={variantItem.value}
                control={<Radio style={{ color: "#1058FB" }} />}
                label={variantItem.label}
              />
            ))}
          </RadioGroup>
        );
      }
      default: {
        break;
      }
    }
  };

  return (
    <Formik {...props}>
      {(formik) => (
        <Form onSubmit={formik.handleSubmit} style={style}>
          {fields.map((input) => (
            <React.Fragment key={input.name}>
              {Boolean(input.label) && (
                <Typography variant="body2" className={classes.subtitle}>
                  {input.label}
                </Typography>
              )}
              <div
                className="flex_box_vertical"
                style={{
                  minHeight: sm ? 80 : 90,
                  justifyContent: "flex-start",
                  marginTop: sm ? 4 : 8,
                }}>
                {getInput(input, formik)}
              </div>
            </React.Fragment>
          ))}
          {action}
        </Form>
      )}
    </Formik>
  );
}

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontWeight: "600 !important",
    color: "#000000",
  },
}));
